<div *ngIf="notificationsList?.length > 0">
  <section class="content scrolleable" style="width: 27vw;">
    <div class="card-item-notifications" *ngFor="let notification of notificationsList">
      <div class="row">
        <div class="col-2" style="text-align: center; align-content: center;">
          <img *ngIf="notification.notificationPhoto != undefined" class="img-fluid rounded-circle border border-dark border-2" style="width: 40px; height: 40px;" [src]="'data:image/png;base64,' + notification.notificationPhoto" />
          <img *ngIf="notification.notificationPhoto == undefined" class="img-fluid" style="width: 22px; height: 24px;" src="assets/img/notification-icon.png" />
        </div>
        <div class="col-10">
          <div class="row">
            <div class="col-12">
              <strong class="pointer">{{ notification.fullName }}</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p>{{ timeSince(notification.notificationSent) }}</p>
            </div>
            <div class="col-5">
              <p class="template-name">{{ notification.templateName | localize }}</p>
            </div>
            <div class="col-4" style="text-align: right;">
              <i class="far fa-bell-slash pointer mr-2 option-item"
                *ngIf="isDropdownOpen(notification.notificationId)"
                [tooltip]="'MarkAsRead' | localize"
                (click)="setNotificationAsRead(notification.notificationId)"></i>
              <i class="fas fa-inbox pointer mr-2 option-item"
                *ngIf="isDropdownOpen(notification.notificationId)"
                [tooltip]="'MarkAsArchived' | localize"
                (click)="setNotificationAsArchived(notification.notificationId)"></i>
              <i class="fas fa-ellipsis pointer" (click)="toggleDropdown(notification.notificationId)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="show-all-button">
    <p class="pointer" (click)="navigateToComponent()">{{ "ShowAllNotifications" | localize }}</p>
  </div>
</div>
<div *ngIf="notificationsList?.length == 0">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mt-1">
          <i class="far fa-bell-slash mr-2"></i>
          <span>{{ "DontHaveUnarchivedNotifications" | localize }}</span>
        </div>
      </div>
    </div>
  </section>
  <div class="show-all-button">
    <p class="pointer" (click)="navigateToComponent()">{{ "ShowAllNotifications" | localize }}</p>
  </div>
</div>
