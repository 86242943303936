import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

// Angular Pipes
import { DatePipe, CurrencyPipe } from '@angular/common';
//import { Nl2BrPipeModule, Nl2BrPipe } from 'nl2br-pipe';
import { LocalizePipe } from '@shared/pipes/localize.pipe';
import { FilterPipe } from '@shared/pipes/filter.pipe';
import { TruncatePipe } from '@shared/pipes/truncate.pipe';
import { FindElementsPipe } from '@shared/pipes/find-elements.pipe';
import { JoinPipe } from '@shared/pipes/join.pipe';
import { CustomCurrencyPipe } from '@shared/pipes/custom-currency.pipe';
import { PersonTypePipe } from '@shared/pipes/person-type.pipe';
import { BalanceMonthPipe } from '@shared/pipes/balance-month.pipe';
import { PersonPositionPipe } from '@shared/pipes/person-position.pipe';
import { PersonFullNamePipe } from '@shared/pipes/person-full-name.pipe';
import { DateTimePipe } from '@shared/pipes/date-time.pipe';
import { DateLessThanCurrentPipe } from '@shared/pipes/date-less-than-current.pipe';
import { DateExactlyLessThanCurrentPipe } from '@shared/pipes/date-exactly-less-than-current.pipe';
import { HighlightPipe } from '@shared/pipes/highlight.pipe';
import { CompanyRoleIdFilterPipe } from '@shared/pipes/company-role-id-filter.pipe';
import { FileTypePipe } from '@shared/pipes/file-type.pipe';
import { SafePipe } from '@shared/pipes/safe.pipe';

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { PaginationPageSizeComponent } from './components/pagination/pagination-page-size/pagination-page-size.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';
import { LayoutStoreService } from './layout/layout-store.service';
import { TenantThemeService } from './layout/tenant-theme.service';
import { CustomModalService } from './helpers/custom-modal.service';

import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { NotEqualValidator } from './directives/not-equal-validator.directive';
import { GreaterThanValidator } from './directives/greater-than-validator.directive';
import { AfterValidator } from './directives/after-validator.directive';
import { GreaterOrEqualThanValidator } from './directives/greater-or-equal-than-validator.directive';
import { GreaterOrEqualThanTwiceValidator } from './directives/greater-or-equal-than-twice-validator.directive';
import { LowerThanValidator } from './directives/lower-than-validator.directive';
import { LowerOrEqualThanValidator } from './directives/lower-or-equal-than-validator.directive';
import { GreaterThanZeroValidator } from './directives/greater-than-zero-validator.directive';
import { OptionInListValidator } from './directives/option-in-list-validator.directive';
import { LowerThanCurrentValidator } from './directives/lower-than-current-validator.directive';
import { EqualOrLowerValueValidator } from './directives/equal-or-lower-value-validator.directive';
import { SpecialCharactersValidator } from './directives/special-characters-validator.directive';
import { ContactPreferenceValidator } from './directives/contact-preference-validator.directive';
import { BeforeOrEqualThanValidator } from './directives/before-or-equal-than-validator.directive';
import { NgxModalDraggableDirective } from './directives/draggable.directive';
import { OrderableTableColumnComponent } from '@shared/components/orderable-table-column/orderable-table-column.component';
import { TablesHelper } from './helpers/TablesHelper';
import { CatalogsLoader } from './helpers/CatalogsLoader';
import { CacheService } from './helpers/cache.service';
import { AddressComponent } from '@shared/components/address/address.component';
import { ContactComponent } from '@shared/components/contact/contact.component';
import { FormsModule } from '@angular/forms';
import { MaritalComponent } from './components/marital/marital.component';
import { BreadcrumbsComponent } from '@shared/components/breadcrumbs/breadcrumbs.component';
import { BreadcrumbsService } from './helpers/breadcrumbs.service';
import { RightMenuComponent } from '@shared/components/right-menu/right-menu.component';
import { RightCalendarComponent } from '@shared/components/right-calendar/right-calendar.component';
import { NotificationsComponent } from '@app/notifications/notifications.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SharedStatusService } from './helpers/shared-status.service';
import { ModalStatusService } from './helpers/modal-status.service';
import { TitleComponent } from '@shared/components/title/title.component';
import { NotificationCountUpdatedService } from './helpers/notification-count-updated.service';
import { LibraryService} from './helpers/library.service';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        //Nl2BrPipeModule,
        BsDropdownModule,
        TooltipModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
    ],
    declarations: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        SpinnerComponent,
        OrderableTableColumnComponent,
        AddressComponent,
        ContactComponent,
        BreadcrumbsComponent,
        RightMenuComponent,
        NotificationsComponent,
        RightCalendarComponent,
        TitleComponent,
        //AppDialogComponentBase,
        // Pipes
        LocalizePipe,
        FilterPipe,
        TruncatePipe,
        FindElementsPipe,
        JoinPipe,
        CompanyRoleIdFilterPipe,
        CustomCurrencyPipe,
        PersonTypePipe,
        BalanceMonthPipe,
        PersonPositionPipe,
        PersonFullNamePipe,
        DateTimePipe,
        DateLessThanCurrentPipe,
        DateExactlyLessThanCurrentPipe,
        HighlightPipe,
        FileTypePipe,
        SafePipe,
        BusyDirective,
        EqualValidator,
        NotEqualValidator,
        GreaterThanValidator,
        AfterValidator,
        GreaterOrEqualThanValidator,
        GreaterOrEqualThanTwiceValidator,
        LowerThanValidator,
        LowerOrEqualThanValidator,
        BeforeOrEqualThanValidator,
        GreaterThanZeroValidator,
        OptionInListValidator,
        EqualOrLowerValueValidator,
        LowerThanCurrentValidator,
        SpecialCharactersValidator,
        ContactPreferenceValidator,
        NgxModalDraggableDirective,
        PaginationPageSizeComponent,
        MaritalComponent
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        SpinnerComponent,
        OrderableTableColumnComponent,
        AddressComponent,
        MaritalComponent,
        ContactComponent,
        BreadcrumbsComponent,
        RightMenuComponent,
        LocalizePipe,
        FilterPipe,
        TruncatePipe,
        FindElementsPipe,
        JoinPipe,
        CompanyRoleIdFilterPipe,
        CustomCurrencyPipe,
        PersonTypePipe,
        BalanceMonthPipe,
        PersonPositionPipe,
        PersonFullNamePipe,
        DateTimePipe,
        DateLessThanCurrentPipe,
        DateExactlyLessThanCurrentPipe,
        HighlightPipe,
        FileTypePipe,
        SafePipe,
        BusyDirective,
        EqualValidator,
        NotEqualValidator,
        GreaterThanValidator,
        AfterValidator,
        GreaterOrEqualThanValidator,
        GreaterOrEqualThanTwiceValidator,
        LowerThanValidator,
        LowerOrEqualThanValidator,
        BeforeOrEqualThanValidator,
        GreaterThanZeroValidator,
        OptionInListValidator,
        EqualOrLowerValueValidator,
        LowerThanCurrentValidator,
        SpecialCharactersValidator,
        ContactPreferenceValidator,
        NgxModalDraggableDirective,
        PaginationPageSizeComponent,
        TitleComponent,
        //AppDialogComponentBase,
        //Nl2BrPipe
    ],
    // The next collection is added for usage in components
    providers: [
        LocalizePipe,
        FilterPipe,
        TruncatePipe,
        FindElementsPipe,
        JoinPipe,
        CompanyRoleIdFilterPipe,
        CustomCurrencyPipe,
        PersonTypePipe,
        BalanceMonthPipe,
        PersonPositionPipe,
        PersonFullNamePipe,
        DateTimePipe,
        DateLessThanCurrentPipe,
        DateExactlyLessThanCurrentPipe,
        DatePipe,
        CurrencyPipe,
        //Nl2BrPipe,
        HighlightPipe,
        FileTypePipe,
        SafePipe,
        TablesHelper,
        CatalogsLoader
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                LayoutStoreService,
                SharedStatusService,
                CacheService,
                BreadcrumbsService,
                RightMenuComponent,
                TenantThemeService,
                NotificationCountUpdatedService,
                LibraryService,
                CustomModalService,
                ModalStatusService,
            ]
        };
    }
}
